.main-menu {
  position: relative;
  width: 100%;
}

.menu-row {
  width: 100%;
  
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-top: 2vh;
}

.menu-row:first-child {
  padding-top: 0;
}

.menu-item-bg {
  width: 18vh;
  height: 18vh;
  max-width: 35vw;
  max-height: 35vw;
  background-color: rgb(0, 113, 188, 0.5);
  border-radius: 20px;
  position: relative;
  margin-right: 2vh;
}
.menu-item-bg:hover {
  background-color: rgb(178, 234, 255, 0.5);
  cursor: pointer;
}
.menu-item-bg:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 980px) {
  .menu-item-bg {
    margin-right: 1vh;
  }
  .menu-row {
    padding-top: 0.5vh;
  }
}

.menu-item {
  position: absolute;
  top: 7%;
  left: 7%;
  box-sizing: border-box;
  background-color: white;
  width: 86%;
  height: 86%;
  border-radius: 15px;
  border: medium solid #214798;
  color: white;
}
.menu-item-bg:hover .menu-item {
  background-color: #29abe2;
}
.menu-item-bg:hover .menu-item p {
  color: white;
}

.game-menu-mobile .menu-item-bg:hover {
  background-color: rgb(0, 113, 188, 0.5);
}
.game-menu-mobile .menu-item-bg:hover .menu-item {
  background-color: white;
}

.menu-item img {
  width: 60%;
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  -ms-transform: translate(-50%, -40%);
  transform: translate(-50%, -40%);
}

.menu-item p {
  font-size: 2vh;
  position: absolute;
  bottom: 0.4vh;
  left: 10%;
  color: #214798;
  font-weight: bold !important;
  text-align: left;
}

.game-menu-mobile .menu-item p {
  color: #214798 !important;
}

.art-icon img {
  width: 50% !important;
}

.mtg-icon img {
  width: 55%;
  top: 30%;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: rgb(0, 113, 188, 0.5);
  z-index: 99999;
}
.left {
  left: 0;
}
.right {
  right: 0;
}

.arrow svg {
  height: 30px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  animation: arrowPulseIt 1.5s infinite;
}

.arrow svg path {
  fill: white;
}
.arrow.inactive svg path {
  fill: darkgrey;
}
.arrow.inactive svg {
  animation: none;
}

@keyframes arrowPulseIt {
  0% { height: 30px; }
  50% { height: 35px; }
  100% { height: 30px; }
}

.transitionRight-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.transitionRight-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.transitionRight-exit {
  opacity: 1;
  transform: translateX(0%);
}
.transitionRight-exit-active {
  opacity: 0;
  transform: translateX(-100%);
}
.transitionRight-enter-active,
.transitionRight-exit-active {
  transition: opacity 300ms, transform 300ms;
}

.transitionLeft-enter {
  opacity: 0;
  transform: translateX(100%);
}
.transitionLeft-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.transitionLeft-exit {
  opacity: 1;
  transform: translateX(0%);
}
.transitionLeft-exit-active {
  opacity: 0;
  transform: translateX(100%);
}
.transitionLeft-enter-active,
.transitionLeft-exit-active {
  transition: opacity 300ms, transform 300ms;
}


@media only screen and (max-height: 600px) {
  .menu-item-bg {
    width: 16vh;
    height: 16vh;
  }
}

@media only screen and (max-height: 500px) {
  .menu-item-bg {
    width: 15vh;
    height: 15vh;
  }
}