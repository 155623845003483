.leaderboard-container {
  width: 60vw;
  max-width: 950px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 10vh;
  background-image: linear-gradient(#214798, #214798, #30106b);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 20px;
  border: medium solid white;
  text-align: center;
}

.leaderboard-container.leaderboard-container-mobile {
  height: 80vh;
  top: 50%;
  transform: translate(-50%, -50%);
}

.leaderboard-container.leaderboard-container-mobile .leaderboard-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  width: 100%;
}

.leaderboard-container h1 {
  color: white;
  font-weight: 300;
  font-size: 2.2em;
  text-shadow: 2px 2px 10px #29abe2;
  margin-top: 0.3em;
}

.leaderboard-container h2 {
  color: white;
  font-weight: 300;
  font-size: 1.8em;
  text-shadow: 2px 2px 10px #29abe2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leaderboard-container h2 img {
  margin-right: 0.4em;
}

.leaderboard-categories-wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 1em;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 1em;
}

.leaderboard-category-wrap {
  outline: none !important;
  border: none !important;
  margin-right: 0.5em;
}

.leaderboard-category-wrap p {
  color: white;
  text-align: center;
  margin-bottom: 0.5em;
  font-weight: 400;
}

.leaderboard-category {
  width: 5em;
  height: 4em;
  background-color: rgb(0, 113, 188, 0.5);
  border: medium solid white;
  border-radius: 20px;
  position: relative;
}

.leaderboard-category:last-child {
  margin-right: 0;
}

.leaderboard-category:hover, .leaderboard-category-wrap.selected .leaderboard-category {
  background-color: rgb(0, 130, 200);
  cursor: pointer;
}

.leaderboard-category img {
  height: 3em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.leaderboard-header {
  display: flex;
  margin: 1em auto 0 auto;
  border-bottom: thin solid white;
  text-align: left;
  width: 90%;
}

.leaderboard-header p {
  width: 33%;
  margin: 0;
  margin-bottom: 0.3em;
  color: white;
  font-weight: 400;
}

.leaderboard-table {
  height: 32vh;
  width: 95%;
  margin: 0 auto;
  margin-top: 1em;
  margin-bottom: 0.5em;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: 2em;
  box-sizing: border-box;
  color: white;
}

.leaderboard-column {
  width: calc(33.3% - 1em);
  text-align: left;
  border-right: medium solid rgb(0, 0, 0, 0.2);
  padding-left: 1em;
  height: auto;
  float: left;
}

.leaderboard-column:first-child {
  padding-left: 0;
}

.leaderboard-column:last-child {
  border-right: none;
}

.leaderboard-column-row {
  display: flex;
  height: auto;
}

.leaderboard-column-row p:first-child {
  width: 11%;
}

.leaderboard-column-row p {
  color: white;
  font-size: 0.9em;
  font-weight: 400;
  width: 46%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.leaderboard-column-row p:last-child {
  width: fit-content;
  overflow: unset;
  text-overflow: unset;
}

.leaderboard-column-row .leaderboard-amount {
  width: 24%;
  margin-left: 0.4em;
}

.leaderboard-column-row.highlited p {
  color: orange !important;
  font-weight: 600;
}

.time-range-menu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 0.5em;
  padding-bottom: 0.5em;
}

.time-range-menu-item {
  width: 30%;
  height: 2em;
  background-color: rgb(0, 113, 188, 0.5);
  border-radius: 10px;
  border: 2px solid white;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.time-range-menu-item.selected {
  background-color: rgb(0, 130, 200);
  border: 2px solid white !important; 
  cursor: pointer;
}

.time-range-menu-item p {
  margin: 0;
  font-weight: 400;
  font-size: 0.9em;
}

.leaderboard-container hr {
  width: 80%;
}

.mobile-ranking p {
  color: orange;
  font-weight: 600;
}

@media only screen and (max-width: 1514px) {
  .leaderboard-table {
    height: 22vh;
  }

  .leaderboard-category {
    width: 4em;
    height: 3.3em;
  }

  .leaderboard-category-wrap {
    margin-right: 0.3em;
  }

  .leaderboard-category img {
    height: 2em;
  }
}

@media only screen and (max-width: 980px) {
  .leaderboard-container {
    width: 90%;
    height: 90%;
  }

  .leaderboard-container h1 {
    font-size: 1.5em;
    margin: 0;
  }
  
  .leaderboard-container h2 {
    font-size: 1.2em;
  }

  .leaderboard-category {
    width: 3.3em;
    height: 2.5em;
    border-radius: 15px;
    border-width: 2px;
  }

  .leaderboard-category img {
    height: 1.8em;
  }

  .leaderboard-categories-wrap {
    padding: 0;
    margin-top: 0.5em;
  }

  .leaderboard-category-wrap {
    margin-right: 0.2em;
    margin-bottom: 0.7em;
  }

  .leaderboard-category-wrap p {
    display: none;
  }

  .leaderboard-table {
    height: 35vh;
    padding-left: 1em;
  }

  .leaderboard-column {
    width: 100%;
  }

  .leaderboard-amount {
    width: 17% !important;
  }

  .leaderboard-column-row p {
    width: 51%;
  }
}