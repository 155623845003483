.leaderboardWrap {
  padding-left: 10px;
  width: 78%;
  height: 5em;
  background-color: rgb(0, 113, 188, 0.5);
  border: medium solid rgb(255, 255, 255, 0.6);
  border-radius: 20px;
  cursor: pointer;
  z-index: 999999;
}

.leaderboardButton {
  width: 350px;
  margin: 0;
  transform: translateY(-50%);
}

.leaderboardButton:hover {
  cursor: pointer;
}

.header {
  display: flex;
  margin: -0.5em 1.5em 0 1em;
  border-bottom: thin solid rgb(255, 255, 255, 0.4);
}

.header p {
  width: 33%;
  margin: 0;
  padding-left: 1em;
  font-size: 0.9em;
  color: white;
  font-weight: 400;
}

.content {
  display: flex;
  margin-top: 0.5em;
  padding-right: 1em;
}

.row {
  width: 33%;
  display: flex;
  border-right: medium solid rgb(255, 255, 255, 0.5);
}

.row:last-child {
  border-right: none;
}

.row p:first-child {
  width: 4%;
}

.row p {
  width: 45%;
  font-weight: 500;
  padding-left: 1em;
  font-size: 0.9em;
}

.row p:last-child {
  width: 40%;
}

.loader {
  transform: scale(0.5) translateY(-100%);
}

@media only screen and (max-height: 720px) {
  .leaderboardWrap {
    margin-left: 180px;
  }
}