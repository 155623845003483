p, a, h1, h2, label {
  font-family: "Montserrat", "Verdana", "Geneva", "Tahoma", "sans-serif";
  font-weight: lighter;
  margin: 0;
}

.app {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  background-color: #c6f1f8;
  background-repeat: no-repeat;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  filter: url("#turbulance");
}

.overlay{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
}

.desktop {
  background-position: bottom right;
}

.mobile {
  background-position: top right;
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  width: 40vw;
  max-width: 550px;
  height: 200px;
}

.logo img {
  width: 23vw;
  max-width: 300px;
  margin-top: 2%;
  margin-left: 12px;
  z-index: 999999;
}

.logo img:hover {
  cursor: pointer;
  z-index: 999999;
}

.main-menu-icon {
  position: absolute;
  top: 0.78em;
  right: 3vw;
  background-color: rgb(0, 113, 188, 0.5);
  width: 14vw;
  height: 2.5em;
  min-width: 250px;
  border-radius: 20px;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -ms-flex-line-pack: justify;
      align-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: x-large;
  z-index: 500;
  border: medium solid whitesmoke;
}

.main-menu-icon svg {
  height: 1.5em;
  margin-bottom: 0.3em;
}

.main-menu-icon:hover {
  color: #214798;
  cursor: pointer;
}

.main-menu-icon:hover svg path {
  fill: #214798;
}

.footer {
  position: absolute;
  bottom: 0;
  color: white;
  width: auto;
  width: 73vw;
  padding-left: 5vw;
  padding-bottom: 2vh;
  box-sizing: border-box;
}
.upper {
  float: left;
  width: auto;
  letter-spacing: 3.5px;
  margin-top: 1em;
}
.upper div {
  float: left;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
}

.upper div p {
  color: white;
  font-weight: 300;
  font-size: 2.1vh;
}

.learn-more {
  margin-left: 1vw;
  background-color: rgb(0, 113, 188, 0.5);
  border-radius: 10px;
  border: medium solid #29abe2;
}

.learn-more a {
  text-decoration: none;
  color: white;
  font-weight: 600;
  font-size: 2.1vh;
}

.learn-more:hover a {
  cursor: pointer;
  color: #29abe2;
}

.bottom {
  width: 75%;
  text-align: center;
  margin-top: 8vh;
}
.bottom a {
  color: #29abe2;
  text-decoration: none;
  font-size: 1.6vh;
  font-weight: bold;
  letter-spacing: 2px;
  flex-grow: 1;
  margin-right: 10%;
}
.bottom a:last-child {
  margin-right: 0;
}
.bottom a:hover {
  color: white;
}

.water-counter {
  position: absolute;
  bottom: 0;
  right: 0;
  width: auto;
  text-align: right;
  padding-right: 2.5vw;
  padding-bottom: 3vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.bottle {
  float: left;
  position: relative;
}
.bottle img {
  height: 22vh;
}
.bottle .confetti {
  position: absolute;
  top: -100%;
  left: 50%;
  transform: translateX(-50%);
}

.counter-wrap {
  float: left;
  align-self: flex-end;
  margin-right: 30px;
  text-align: center;
  color: white;
  background-color: rgb(0, 113, 188, 0.5);
  padding: 1.5vh;
  border-radius: 10px;
}

.counter-wrap .percentage {
  font-size: 2.4vh;
}

.counter {
  width: 25vh;
  height: 9vh;
  background-color: #29abe2;
  border-radius: 20px;
  text-align: center;
}

.counter p {
  font-weight: 400;
}
.counter p:last-child {
  font-weight: 500;
}

.counter-wrap p {
  font-size: 3.5vh;
}

.donated {
  letter-spacing: 5px;
}

.counter p {
  color: white;
  font-size: 8vh;
  line-height: 9vh;
  text-align: center;
  display: inline;
}

.counter p:last-child {
  margin-left: 0.5em;
}

.counter-bottle-mobile {
  width: 90vw;
  height: auto;
  padding-top: 1vh;
  padding-bottom: 0.5vh;
  background-color: rgb(0, 113, 188, 0.5);
  border-radius: 15px;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 50%;
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.bottle-mobile {
  float: left;
}
.bottle-mobile img {
  height: 10.5vh;
  margin: 0;
}
.counter-wrap-mobile {
  margin-left: 10vw;
  text-align: center;
  color: white;
  height: 100%;
}
.percentage {
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 2.1vh;
}
.counter-mobile {
  width: 40vw;
  height: 5.5vh;
  background-color: #29abe2;
  float: left;
  border-radius: 10px;
  text-align: center;
}

.counter-mobile p {
  display: inline;
  color: white;
  font-size: 5vh;
  font-weight: 500;
  line-height: 5.5vh;
}
.counter-mobile p:last-child {
  font-weight: lighter;
}

.donated-mobile {
  margin-left: 3px;
  display: block !important;
  font-size: 3.1vh !important;
  line-height: 3.1vh !important;
  letter-spacing: 5px;
}

.footer-mobile {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 1.5vh;
  text-align: center;
}
.footer-mobile a {
  display: inline;
  font-size: 1.5vh;
  color: #29abe2;
  font-weight: bold;
  margin-right: 1.5vw;
  margin-left: 1.5vw;
  text-decoration: none;
  text-shadow: 1px 1px 10px #214798;
  letter-spacing: 1px;
}
.footer-mobile a:hover {
  color: white;
}

@media only screen and (max-width: 360px) {
  .footer-mobile a {
    font-size: 1.4vh;
    letter-spacing: 0.5px;
  }
}

.total-counter {
  position: absolute;
  width: 90vw;
  height: auto;
  background-color: rgb(0, 113, 188, 0.5);
  bottom: 8vh;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 15px;
  color: #1d1867;
  font-family: "Montserrat", "Verdana", "Geneva", "Tahoma", "sans-serif";
  font-weight: normal;
  text-align: center;
  padding-top: 1vh;
  padding-bottom: 1vh;
  font-size: 2vh;
}

.total-counter p:first-child {
  margin-bottom: 0.5vh;
  font-size: 2.2vh;
}

.total-counter p {
  font-weight: 400;
}

.emphased {
  color: white;
  font-weight: bold;
}

.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 500ms, transform 500ms;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 500ms, transform 500ms;
}

.sound-toggle-mobile {
  position: absolute;
  top: 3vh;
  right: 4vw;
  z-index: 999;
}

.sound-toggle-mobile:hover {
  cursor: pointer;
}

.sound-toggle-mobile img {
  height: 4vh;
}

.sidebar-left {
  width: 200px;
  height: auto;
  position: fixed;
  top: 5.5em;
  left: 3%;
  z-index: 9999;
}

.sidebar-right {
  width: 200px;
  height: 70vh;
  position: fixed;
  top: 8.2em;
  right: 5%;
  z-index: 9999;
}

.signin-button {
  width: 100%;
  height: 3em;
  background-color: rgb(0, 113, 188, 0.5);
  border: medium solid white;
  border-radius: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 99;
}

.signin-button img {
  height: 2em;
  margin-right: 0.5em;
}

.signin-button p {
  color: white;
  font-weight: 500;
}

.signin-button:hover {
  cursor: pointer;
}

.signin-button:hover p {
  color: #214798;
}

.add-wrap {
  position: relative;
  width: 200px;
  height: 390px;
  margin: 0 auto;
  margin-top: 0.5em;
  background-color: rgb(0, 113, 188, 0.5);
  border-radius: 20px;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
}

.add-wrap p:first-child {
  width: fit-content;
  position: absolute;
  right: 1em;
  top: 0.2em;
  font-size: 0.9em;
}

.add-wrap img {
  width: 60%;
  margin-top: 1.5em;
}

.amazon-ad {
  width: 85% !important;
}

.add-wrap p{
  color: white;
  font-weight: 600;
  font-size: 1.1em;
}

.ad-star-mark {
  position: absolute;
  top: -1.6em;
  right: 1.5em;
  width: 2em !important;
}

.sidebar-right .add-wrap {
  border: medium solid goldenrod;
  width: 230px;
  height: 410px;
  cursor: auto;
}

.amazon-a-logo {
  width: 3em !important;
  margin-top: 0.5em !important;
}

.sidebar-right .add-wrap hr {
  width: 80%;
  color: white;
  background-color: white;
  height: 1px;
}

.sidebar-right .add-wrap p {
  font-size: 1em;
}

.sidebar-right .add-wrap .small {
  font-size: 0.8em;
}

.sidebar-right .add-wrap textarea {
  opacity: 0;
}

.link-copy-button {
  width: 90%;
  height: 2em;
  background-color: orange;
  border: medium solid yellow;
  border-radius: 15px;
  margin: 0 auto;
  position: relative;
  margin-top: 1em;
}

.link-copy-button:hover {
  background-color: orangered;
  border: medium solid yellow;
  cursor: pointer;
}

.link-copy-button p {
  color: white;
  margin: 0;
  font-size: 0.65em !important;
  top: 45% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  font-weight: 700;
}

.amzn-bookmark {
  margin-top: 1em;
}

.bookmark-img {
  width: 90% !important;
  margin: 0.5em 0 0 0 !important;
}

.user-info-wrap {
  width: calc(100% - 3.5em);
}

.user-info-wrap p {
  width: 100%;
  font-size: 0.75em;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-info-wrap .cups-donated {
  color: orange;
}

.signin-button:hover .user-info-wrap p {
  color: white !important;
}

.signin-button:hover .user-info-wrap .cups-donated {
  color: orange !important;
}

@media only screen and (max-width: 1140px) {
  .sidebar-left {
    left: 0.5em;
  }
  .sidebar-right {
    right: 2em;
  }
}

@media only screen and (min-width: 980px) and (max-height: 730px) {
  .main-menu-icon {
    width: 9em;
    min-width: unset;
    height: 1.8em;
    top: 0.3em;
  }

  .sound-toggle {
    top: 3.25em !important;
    padding: 0.3em 0.5em;
  }

  .sidebar-right {
    top: 4.7em;
    right: 4em;
    transform: scale(0.9);
  }

  .bottle img {
    height: 120px;
  }
}

.bg1 {
  transition: background 0.5s linear;
}
.bg2 {
  transition: background 0.5s linear;
}
.bg3 {
  transition: background 0.5s linear;
}
.bg4 {
  transition: background 0.5s linear;
}
.bg5 {
  transition: background 0.5s linear;
}
.bg6 {
  transition: background 0.5s linear;
}
.bg7 {
  transition: background 0.5s linear;
}
.bg8 {
  transition: background 0.5s linear;
}
.bg9 {
  transition: background 0.5s linear;
}
.bg10 {
  transition: background 0.5s linear;
}
.bg11 {
  transition: background 0.5s linear;
}
.bg12 {
  transition: background 0.5s linear;
}
.bg13 {
  transition: background 0.5s linear;
}
.bg14 {
  transition: background 0.5s linear;
}
.bg15 {
  transition: background 0.5s linear;
}
.bg16 {
  transition: background 0.5s linear;
}
.bg17 {
  transition: background 0.5s linear;
}