.totalCounter {
  position: absolute;
  width: 45vw;
  min-width: 700px;
  height: 200px;
  top: 0;
  right: 0;
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;
  text-align: right;
  padding-right: 3vw;
  padding-top: 1.5vh;
  color: #1d1867;
}

.totalCounter p {
  font-size: 2.5vh;
  font-weight: 400;
}

.totalCounter p:first-child {
  font-size: 3.2vh;
}