.img-div p {
  position: absolute;
  bottom: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  color: white;
  font-weight: 500;
  text-shadow: 1px 1px 5px black;
  text-align: center;
}