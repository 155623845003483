.game-menu {
  width: 95%;
  height: auto;
  text-align: center;
  margin: 0 auto;
  margin-top: 7%;
}

@media only screen and (max-width: 980px) {
  .game-menu {
    margin-top: 10%;
  }
}

.game-menu p {
  color: white;
  font-size: 2.2em;
  margin: 0;
  font-weight: 500;
  margin-top: -6px;
}

.game-menu.game-menu-mobile p:first-child {
  background-color: unset;
  display: inline;
  padding: 0;
  border-radius: unset;
}

.featured-header {
  width: 75vh;
  margin: 0 auto;
  background-color: rgb(0, 113, 188, 0.5);
  border-radius: 10px;
  padding: 1%;
  margin-bottom: 1vh;
}

.featured-header img {
  width: 300px;
}

.featured-header h1 {
  font-size: 1.4em;
  font-weight: 500;
  color: white;
}

.featured-header h2 {
  font-size: 1.2em;
  font-weight: 500;
  color: white;
  letter-spacing: 2px;
  margin-bottom: 0.5em;
}

.game-menu.game-menu-mobile .signin-button {
  width: 90%;
  margin: 0 auto;
  box-sizing: border-box;
  height: 2em;
  border-width: thin;
  border-radius: 10px;
  background-color: rgb(0, 113, 188, 0.7);
  margin-bottom: 0.5em;
}

.game-menu.game-menu-mobile .signin-button p {
  font-size: 1em;
  margin: 0;
}

.game-menu.game-menu-mobile .signin-button img {
  height: 1.2em;
}

.view-leaderboard-button {
  width: 90%;
  margin: 0 auto;
  box-sizing: border-box;
  height: 2em;
  border: thin solid white;
  background-image: linear-gradient(to right, #214798, rgb(0, 113, 188, 0.7));
  border-radius: 10px;
  margin-bottom: 0.3em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 99;
}

.view-leaderboard-button p {
  font-size: 1.2em !important;
  font-weight: 600;
  margin: 0;
  pointer-events: none;
}

.view-leaderboard-button svg {
  height: 1.2em;
  margin-right: 1em;
  pointer-events: none;
}

.view-leaderboard-button svg path {
  color: gold;
}

.orange {
  color: orange;
}

@media only screen and (max-width: 980px) {
  .featured-header {
    padding: 0.2em 0;
  }

  .featured-header h1 {
    font-size: 1em;
  }
  .featured-header h2 {
    font-size: 3.3vw;
    letter-spacing: 1px;
    margin-bottom: 0.5%;
  }
  .game-menu p {
    font-size: 1.65em;
  }
}

.game-menu-mobile .featured-header {
  width: 90%;
}
.game-menu-mobile .featured-header img {
  width: 100%;
}