.create-account-container {
  width: 90vw;
  max-width: 700px;
  height: 520px;
  position: absolute;
  left: 50%;
  top: 14vh;
  background-image: linear-gradient(#214798, #214798, #30106b);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 20px;
  border: medium solid white;
  text-align: center;
}

.create-account-container.create-account-container-mobile {
  height: 80vh;
  top: 50%;
  transform: translate(-50%, -50%);
}

.create-account-container.create-account-container-mobile .create-account-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.8);
}

.create-account-container.create-account-container-mobile h1 {
  font-size: 1.8em;
  margin-top: 0;
}

.create-account-container h1 {
  color: white;
  font-weight: 600;
  font-size: 2.2em;
  text-shadow: 2px 2px 10px #29abe2;
  margin-top: 0.3em;
}

.create-account-container p {
  color: white;
  margin-top: 0.2em;
}

.create-account-container hr {
  width: 60%;
  border-color: yellow;
}

.create-account-container .input {
  border: medium solid white;
  border-radius: 20px;
  width: 260px;
  height: 2em;
  margin: 0 auto;
  background-color: rgb(0, 113, 188);
  margin-top: 0.4em;
  display: flex;
  align-items: center;
}

.create-account-container .choose-username {
  width: fit-content;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.create-account-container .choose-password {
  width: fit-content;
  margin: 0.5em auto 0 auto;
  display: flex;
  align-items: center;
}

.create-account-container .choose-username .image-wrap,
.create-account-container .choose-password .image-wrap {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 2em;
  height: 2em;
  margin-right: 0.5em;
}

.create-account-container .choose-username p {
  font-weight: 400;
  margin-bottom: 2px;
}

.create-account-container .image-wrap.lock {
  background-size: 1em;
}

.create-account-container .input input {
  border: none;
  background-color: transparent;
  outline: none;
  color: black;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  font-size: 1em;
  background-color: rgb(255, 255, 255, 0.8);
}

.create-account-container .input input[value=""] {
  background-color: transparent;
}

.create-account-container .input:hover {
  cursor: pointer;
}

.create-account-container .input input::placeholder {
  color: white;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-shadow: 2px 2px 10px rgb(255, 255, 255, 0.5);
}

.create-account-container .age-verification {
  text-align: left;
  width: 360px;
  margin: 0 auto;
}

.create-account-container .age-verification .radio-input {
  display: inline-block;
  width: 51%;
}

.create-account-container .age-verification .radio-input:last-child {
  width: calc(49% - 10px);
  margin-left: 10px;
}

.create-account-container .age-verification .radio-input label {
  color: white;
  font-size: 0.9em;
}

.create-account-container .age-verification .radio-input input {
  margin: 0;
  margin-right: 0.5em;
}

.create-account-container .age-verification p {
  font-weight: 500;
  margin-top: 0.5em;
}

.create-account-container a:hover {
  text-decoration: underline;
}

.recaptcha-wrap {
  margin: 0 auto;
  width: 300px;
  transform: scale(0.8);
}

.create-account-container .create-account-button {
  width: 260px;
  height: 2em;
  background-color: orange;
  border: medium solid yellow;
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 0.2em;
  display: flex;
  align-items: center;
}

.create-account-container .create-account-button p {
  font-weight: 600;
  margin-bottom: 2px;
}

.create-account-container .create-account-button svg {
  width: 20px;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.create-account-container .create-account-button svg path {
  color: yellow;
}

.create-account-button:hover {
  cursor: pointer;
  background-color: orangered;
}

.create-account-error {
  color: red !important;
  font-size: 0.9em;
  margin: -0.2em 0 0.3em 0 !important;
}