.my-account-container {
  width: 90vw;
  max-width: 700px;
  height: 460px;
  position: absolute;
  left: 50%;
  top: 17vh;
  background-image: linear-gradient(#214798, #214798, #30106b);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 20px;
  border: medium solid white;
  text-align: center;
}

.my-account-container.my-account-container-mobile {
  height: 80vh;
  top: 50%;
  transform: translate(-50%, -50%);
}

.my-account-container.my-account-container-mobile .my-account-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  width: 100%;
}

.my-account-content img {
  width: 80px;
  margin-top: 1.5em;
}

.my-account-container h1 {
  color: white;
  font-weight: 600;
  font-size: 2.2em;
  text-shadow: 2px 2px 10px #29abe2;
  margin-top: 0.3em;
}

.my-account-container h1:last-child {
  margin-top: 1em;
}

.my-account-container p {
  color: orange !important;
  font-size: 1.8em;
  font-weight: 600;
  text-shadow: 2px 2px 10px rgb(255, 255, 255, 0.4);
  margin: 0.5em 0 1em 0 !important;
}

.my-account-container.my-account-container-mobile h1 {
  margin-top: 0;
  font-size: 1.8em;
}

.my-account-container p {
  color: white;
  margin-top: 0.2em;
}

.my-account-container hr {
  width: 50%;
  border-color: yellow;
}

.edit-profile-button {
  width: 90%;
  max-width: 200px;
  height: 2em;
  border-radius: 20px;
  border: medium solid yellow;
  background-color: orange;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.edit-profile-button p {
  color: white !important;
  font-size: 1em;
  margin: 0 !important;
}

.edit-profile-button:hover {
  background-color: orangered;
  cursor: pointer;
}

.sign-out-button {
  width: 90%;
  max-width: 200px;
  height: 2em;
  border-radius: 20px;
  border: medium solid rgb(255, 255, 255, 0.5);
  background-color: rgb(0, 113, 188, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em auto 0 auto !important;
}

.sign-out-button p {
  color: white !important;
  font-size: 1em;
  margin: 0 !important;
}

.sign-out-button:hover {
  border: medium solid rgb(255, 255, 255, 0.7);
  background-color: rgb(0, 113, 188, 0.5);
  cursor: pointer;
}