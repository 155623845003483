.sound-toggle {
  color: white;
  display: flex;
  align-items: center;
  float: right;
  margin-top: 0.5em;
  z-index: 999;
  background-color: rgb(0, 113, 188, 0.5);
  padding: 0.5em;
  border: medium solid white;
  border-radius: 15px;
}
.sound-toggle:hover {
  cursor: pointer;
}
.sound-toggle p {
  font-size: 1em !important;
  font-weight: 600 !important;
  letter-spacing: 1.5px;
  display: inline;
}
.sound-toggle svg {
  height: 1.5em;
  margin-right: 0.5em;
}