.slider-wrap {
  width: 95%;
  height: 8.5vh;
  background-color: #214798;
  border: medium solid white;
  border-radius: 10px;
  padding-top: 1.5vh;
  padding-right: 1.5vh;
  padding-left: 1.5vh;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.value-box {
  float: left;
  margin: 0 auto;
  width: 9vw;
  max-width: 70px;
}

.value-box p {
  line-height: 5vh;
  font-weight: 400;
}

.preYear {
  line-height: unset !important;
  font-size: 2vh;
}

.react-slider-wrap {
  width: 70%;
  height: 8.5vh;
  margin: 0 auto;
  float: left;
}

.slider-thumb {
  width: 3vh;
  height: 5vh;
  background-color: #214798;
  border: medium solid white;
  border-radius: 7px;
  box-sizing: border-box;
  outline: none !important;
  background-image: url("../../assets/slider-grip.png");
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: center center;
}

.slider-track {
  height: 1.2vh;
  margin-top: 1.9vh;
  margin-bottom: 1.9vh;
  background-color: #29abe2;
  box-sizing: border-box;
  border-radius: 5px;
}

.slider-track-0 {
  background-color: whitesmoke;
}

.slider-track-2 {
  background-color: whitesmoke;
}