.play-button-wrap {
  width: 190px;
  height: 70px;
  background-color: rgb(0, 113, 188, 0.5);
  border-radius: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
}

.play-button {
  background-color: orange;
  border: medium solid yellow;
  border-radius: 10px;
  width: 88%;
  height: 67%;
  color: white;
  text-align: center;
  z-index: 500;
}

.play-button p {
  margin-left: 4%;
  font-size: 35px;
  font-weight: bold;
  letter-spacing: 10px;
  line-height: 47px;
  text-shadow: 1px 1px 10px yellow;
}

.play-button:hover {
  cursor: pointer;
  background-color: orangered !important;
  border: medium solid orange;
}

@media only screen and (max-height: 730px) {
  .play-button-wrap {
    width: 150px;
    height: 60px;
  }

  .play-button p {
    font-size: 25px;
    letter-spacing: 8px;
    line-height: 40px;
  }
}

.play-button.valid {
  animation: fadeIt 2s infinite;
}
.play-button-wrap.valid {
  animation: pulseIt 1.5s infinite;
}

@keyframes fadeIt {
  0%   { background-color: orange; }
  50% { background-color: orangered; }
  100% { background-color: orange; }
}

@keyframes pulseIt {
  0%   { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}