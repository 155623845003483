.panel {
  width: 90vw;
  max-width: 650px;
  height: 65%;
  position: absolute;
  left: 50%;
  top: 57%;
  -ms-transform: translate(-50%, -57%);
  transform: translate(-50%, -57%);
  text-align: center;
  background-color: rgb(0, 113, 188, 0.5);
  border-radius: 25px;
  padding-right: 1vw;
  padding-left: 1vw;
  padding-bottom: 2%;
}

.panel p {
  font-family: "Montserrat", "Verdana", "Geneva", "Tahoma", "sans-serif";
  color: white;
  font-weight: 300;
}

.panel h2 {
  color: white;
  font-family: "Montserrat", "Verdana", "Geneva", "Tahoma", "sans-serif";
}

.panel li {
  color: white;
  font-family: "Montserrat", "Verdana", "Geneva", "Tahoma", "sans-serif";
}

.panel a {
  color: white;
}

.mobile-panel {
  left: 50% !important;
  top: 28% !important;
  -ms-transform: translate(-50%, -30%) !important;
  transform: translate(-50%, -30%) !important;
}

.mobile-panel h1 {
  margin-top: 0.25em !important;
  margin-bottom: 0.25em !important;
  font-size: 1.5em !important;
}

.panel h1 {
  color: white;
  font-family: "Montserrat", "Verdana", "Geneva", "Tahoma", "sans-serif";
  font-weight: lighter;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 2em;
}

.content-wrap {
  width: 100%;
  height: 90%;
  position: relative;
}

.panel-content-container {
  overflow-y: scroll;
  height: 96%;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 1.5vw;
}

.content-bg {
  margin-left: 1.5vw;
  position: absolute;
  top: 0;
  left: 0;
  width: 83vw;
  max-width: calc(650px - 3.6vw);
  height: 96%;
  background-color: #214798;
  border: medium solid white;
  border-radius: 10px;
  z-index: -1;
}

.panel-content-container::-webkit-scrollbar, .leaderboard-table::-webkit-scrollbar {
    width: 1em;
}
  
.panel-content-container::-webkit-scrollbar-track, .leaderboard-table::-webkit-scrollbar-track {
    background-color: #214798;
    border: thin solid white;
    border-radius: 10px;
}

.panel-content-container::-webkit-scrollbar-thumb, .leaderboard-table::-webkit-scrollbar-thumb {
    background-color: #29abe2;
    border: medium solid white;
    border-radius: 10px;
    background-image: url("../../assets/slider-grip.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

.panel-content {
  width: 96%;
  padding-right: 2em;
  padding-left: 1.8em;
  box-sizing: border-box;
  text-align: left;
  font-size: 1.7vh;
}

.panel-content p {
  color: white;
  margin-top: 1em;
}