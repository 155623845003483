.game-panel {
  width: 100%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.game-panel.mobile {
  width: 100% !important;
}

.in-game-tagline {
  color: white;
  text-align: center;
  width: 100%;
}

.in-game-tagline-bg {
  width: fit-content;
  margin: 0 auto;
  padding: 10px;
  border-radius: 10px;
  background-color: rgb(0, 113, 188, 0.5);
  margin-top: 5px;
  margin-bottom: 5px;
}
.in-game-tagline-bg h1 {
  margin: 0 !important;
}

.in-game-tagline h1, .in-game-tagline p {
  font-family: "Montserrat", "Verdana", "Geneva", "Tahoma", "sans-serif";
  margin: 0;
  font-weight: normal;
}

.in-game-tagline p {
  font-size: 0.9em;
}

.in-game-tagline h1 {
  font-size: 1.6em;
  letter-spacing: 1px;
  margin-top: 3vh;
}

.question-container {
  width: 90%;
  max-width: 400px;
  height: 88%;
  max-height: 620px;
  padding-top: 1.5%;
  background-color: rgb(0, 113, 188, 0.5);
  border-radius: 20px;
}

.react-card-flip {
  height: 100%;
  width: 100%;
}

.question-container.mobile {
  background-color: transparent !important;
  padding-top: 0;
  height: 78%;
}

.img-container {
  height: 63%;
  width: 100%;
  margin-bottom: 4%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.img-div {
  height: 100%;
  width: 90%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin: 0 auto;
  position: relative;
}

.img-container.mobile {
  margin-bottom: 1vh !important;
}

.img-container.mobile img {
  box-shadow: 1px 1px 30px rgb(0, 113, 188, 0.9);
}

.aswers-container {
  width: 100%;
  height: 35%;
  margin: 0 auto;
  box-sizing: border-box;
}

.aswers-container.mobile {
  background-color: rgb(0, 113, 188, 0.5) !important;
  padding: 3% 0 3% 0;
  border-radius: 15px;
}

.answer-item {
  width: 80%;
  height: 21.5%;
  max-height: 42px;
  margin: 0 auto;
  background-color: #214798;
  border: medium solid white;
  border-radius: 10px;
  margin-bottom: 1.2%;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-left: 0.8em;
  padding-right: 0.8em;
}

.aswers-container.mobile .answer-item {
  width: 95%;
  height: 23%;
  max-height: unset;
  padding-left: 0;
  padding-right: 0;
}

.answer-item:hover {
  color: #29abe2;
  border: medium solid #29abe2;
  cursor: pointer;
}

.aswers-container.mobile .answer-item:hover {
  color: white;
  border: medium solid white;
  cursor: pointer;
}

.answer-item p {
  font-size: 90%;
  font-weight: 500;
}

.selected {
  border: medium solid #29abe2 !important;
}

.correct {
  background-color: greenyellow;
  color: #29abe2 !important;
}

.incorrect-selected {
  background-color: red;
  color: white !important;
}

.in-game-counter-wrap-mobile {
  margin-left: 1.5em;
  height: auto;
  box-sizing: content-box;
}
.in-game-counter-mobile {
  width: 40vw;
  height: 45%;
  background-color: #29abe2;
  border-radius: 10px;
  text-align: center;
}

.in-game-counter-mobile p {
  display: inline;
  color: white;
  font-size: 4.5vh;
  line-height: 5vh;
  font-weight: 500;
}

.in-game-donated-mobile {
  margin-left: 3px;
  display: block !important;
  font-size: 1.1em !important;
  line-height: 1.1em !important;
  letter-spacing: 5px;
  margin-top: 1%;
  margin-bottom: 1%;
}

.in-game-bottle-mobile {
  float: left;
  height: 90%;
  position: relative;
}

.in-game-bottle-mobile .confetti {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%);
}

.in-game-bottle-mobile img {
  height: 100%;
  margin: 0;
}

.in-game-counter-bottle-mobile {
  width: 65vw;
  height: 100%;
  margin: 0 auto;
  padding-top: 1vh;
  margin-top: 1vh;
  background-color: rgb(0, 113, 188, 0.5);
  border-radius: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  box-sizing: content-box;
}

.in-game-counter-bottle-mobile-wrap {
  width: 100%;
  height: 13%;
  box-sizing: content-box;
  color: white;
  text-align: center;;
}

.score {
  width: 80%;
  margin: 0 auto;
  height: 100%;
  border: medium solid white;
  border-radius: 15px;
  background-image: linear-gradient(#214798, #214798, #30106b);
  text-align: center;
  color: white;
  font-weight: 800;
  padding-left: 0.5em;
  padding-right: 0.5em;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.score img {
  width: 22%;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.orange-text {
  color: orange;
}

.score h1 {
  font-size: 1.7em;
}

.score h2 {
  font-size: 1.2em;
  margin-top: 0.5em;
}

@media only screen and (max-width: 980px) {
  .score h1 {
    font-size: 1.3em;
  }
  
  .score h2 {
    font-size: 0.9em;
    margin-top: 0.9em;
  }
}

@media only screen and (min-width: 980px) and (max-height: 700px) {
  .score h1 {
    font-size: 1.3em;
  }
  
  .score h2 {
    font-size: 0.85em;
    margin-top: 0.8em;
  }

  .ad-h2-text {
    margin: 0.8em 0 0 0 !important;
    font-size: 0.85em !important;
  }

  .ad-p-text {
    font-size: 0.75em !important;
  }
}

@media only screen and (max-height: 570px) {
  .score {
    width: 100%;
  }

  .score h1 {
    font-size: 1.2em;
  }
  
  .score h2 {
    font-size: 0.8em;
    margin-top: 0.5em;
  }

  .ad-h2-text {
    margin: 0.5em 0 0 0 !important;
    font-size: 0.8em !important;
  }

  .ad-p-text {
    font-size: 0.7em !important;
  }
}

.score p {
  margin-top: 0.5em;
}

.keep-playing {
  border: medium solid yellow;
  background-color: orange;
  font-size: 1.3em;
  text-shadow: 1px 1px 10px yellow;
}

.keep-playing:hover {
  border: medium solid orange;
  background-color: orangered;
  font-size: 1.3em;
  text-shadow: 1px 1px 10px orange;
  color: white !important;
}

.mobile-add-popup {
  width: 90%;
  height: 90%;
  background-image: linear-gradient(#214798, #214798, #30106b);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  border: medium solid white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-add-popup p {
  color: white;
  font-size: 1em;
  font-weight: 600;
}

.mobile-add-popup .amzn-ad-mobile {
  width: 65%;
  margin: 0.5em 0;
}

.mobile-ad-or {
  margin: 0.2em 0;
}

.shop-now-button {
  width: 65%;
  height: 2em;
  border: medium solid white;
  border-radius: 15px;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #214798;
}

.shop-now-button p {
  margin: 0;
  text-shadow: 2px 2px 8px rgb(255, 255, 255, 0.7);
  font-weight: 400;
  color: white;
}

.shop-now-button img {
  height: 2em;
}

.mobile-ad-donate-text {
  margin-bottom: 0.5em;
}

.mobile-add-popup .mobile-ad-line {
  font-size: 0.8em;
}

.answer-item img {
  height: 2em;
  margin-top: -0.2em;
  margin-right: 1em;
}

.cookies-popup-bg.ad .mobile-add-popup {
  max-width: 400px;
  max-height: 700px;
}

.score.ad .ad-h2-text {
  font-size: 0.9em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.score.ad .ad-p-text {
  font-size: 0.8em;
}

@media only screen and (max-width: 980px) {
  .amazon-a-logo {
    width: 2.5em !important;
  }
}