.albums-menu-wrap {
  position: absolute;
  left: 50%;
  top: 35%;
  width: 90vw;
  max-width: 450px;
  height: auto;
}

.albums-menu {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-right: 4%;
  padding-left: 4%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.albums-menu-item {
  width: 31%;
  height: 7.5vh;
  max-height: 60px;
  background-color: #214798;
  border-radius: 10px;
  border: medium solid white;
  margin-bottom: 1.5vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.albums-menu-item p {
  font-size: 1em;
  text-shadow: 1px 1px 8px #29abe2;
  font-weight: 400;
}

@media only screen and (max-width: 980px) {
  .albums-menu-item p {
    font-size: medium !important;
  }
}

.albums-menu-item.selected {
  color: #29abe2;
  border: medium solid #29abe2;
  cursor: pointer;
}

.albums-menu-item:hover {
  cursor: pointer;
}

@media only screen and (min-width: 980px) {
  .albums-menu-item:hover {
    color: #29abe2;
    border: medium solid #29abe2;
    cursor: pointer;
  }
}
