.cookies-popup-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(204, 240, 255, 0.5);
  z-index: 999999999;
}

.cookies-popup {
  width: 95%;
  max-width: 1000px;
  height: auto;
  max-height: 97%;
  padding: 20px;
  padding-bottom: calc(20px - 1em);
  border-radius: 10px;
  border: medium solid white;
  background-image: linear-gradient(#214798, #214798, #30106b);
  color: white;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -45%);
  text-align: center;
  box-sizing: border-box;
}

.cookies-popup-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.cookies-popup-header img {
  height: 6em;
}

.cookies-popup-header img:first-child {
  margin-right: 32px;
  margin-left: 32px;
}

.cookies-popup-main-img {
  height: 10em !important;
}

.cookies-popup p {
  display: inline-block;
  font-weight: 400;
  margin-bottom: 2.4vh !important;
  text-align: center;
  font-size: 2.5vh;
  margin-bottom: 4vh;
}

.cookies-notice {
  font-size: 1.8vh !important;
}

.dismiss {
  background-color: orange;
  border: medium solid yellow;
  color: white;
  border-radius: 7px;
  margin-bottom: 3vh;
  outline: none !important;
  padding: 0.8em 1.5em 0.8em 1.5em;
  font-size: 2.5vh;
  letter-spacing: 1px;
  text-shadow: 1px 1px 10px white, -1px -1px 10px whitesmoke;
}

.dismiss:hover {
  cursor: pointer;
  background-color: orangered;
  border: medium solid orange;
  text-shadow: 1px 1px 10px yellow, -1px -1px 10px yellow;
}

.cookies-popup-emphased {
  color: orange;
  font-weight: 600;
}

.cookies-popup-main-text {
  width: 95%;
}

.cookies-popup-mobile-img {
  height: 5em;
  margin-top: -0.5em;
}

.cookies-popup-mobile-main-img {
  width: 90%;
  margin-bottom: 0.5em;
}

.cookies-popup-h2-mobile {
  font-size: 1em;
  margin: 0 0 0.5em 0;
}

.cookies-popup .cookies-popup-main-text-mobile {
  width: 100%;
  margin-bottom: 0.2em !important;
}

.cookies-notice-mobile {
  font-size: 0.5em !important;
}