.no-scroll-panel {
  background-color: rgb(0, 113, 188, 0.5);
  border-radius: 20px;
  color: white;
  text-align: center;
  margin-bottom: 1vh;
}

.no-scroll-panel h1 {
  font-family: "Montserrat", "Verdana", "Geneva", "Tahoma", "sans-serif";
  font-weight: lighter;
  display: inline-block;
  margin-top: 1vh;
  margin-bottom: 1.5vh;
  font-size: 4vh;
}
.play-button-container {
  margin-top: 3vh;
}