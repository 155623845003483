.tagline {
  position: absolute;
  top: 3vh;
  left: 50%;
  width: fit-content;
  text-align: center;
  transform: translateX(-50%);
}

.tagline.mobile {
  margin-top: 12%;
  top: 0 !important;
  top: 12vh;
}

.tagline.mobile img {
  width: 75vw !important;
  max-width: 450px;
}