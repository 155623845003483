.tv-menu-wrap {
  position: absolute;
  left: 50%;
  top: 45%;
  -ms-transform: translate(-50%, -45%);
  transform: translate(-50%, -45%);
  width: 90vw;
  max-width: 450px;
  height: auto;
}

.tv-menu {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-right: 6%;
  padding-left: 6%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}