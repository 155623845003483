.auth-container {
  width: 90vw;
  max-width: 700px;
  height: 460px;
  position: absolute;
  left: 50%;
  top: 17vh;
  background-image: linear-gradient(#214798, #214798, #30106b);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 20px;
  border: medium solid white;
  text-align: center;
}

.auth-container.auth-container-mobile {
  height: 80vh;
  top: 50%;
  transform: translate(-50%, -50%);
}

.auth-container.auth-container-mobile .auth-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
}

.auth-container h1 {
  color: white;
  font-weight: 600;
  font-size: 2.2em;
  text-shadow: 2px 2px 10px #29abe2;
  margin-top: 0.3em;
}

.auth-container.auth-container-mobile h1 {
  margin-top: 0;
}

.auth-container p {
  color: white;
  margin-top: 0.2em;
}

.auth-container hr {
  width: 50%;
  border-color: yellow;
}

.auth-container .input {
  border: medium solid white;
  border-radius: 20px;
  width: 260px;
  height: 2.5em;
  margin: 0 auto;
  background-color: rgb(0, 113, 188);
  margin-top: 0.8em;
  display: flex;
  align-items: center;
}

.auth-container .input .image-wrap {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 2em;
  height: 2em;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.auth-container .input .image-wrap.lock {
  background-size: 1em;
}

.auth-container .input input {
  border: none;
  background-color: transparent;
  outline: none;
  color: black;
  background-color: rgb(255, 255, 255, 0.8);
  width: calc(100% - 5em);
}

.auth-container .input input[value=""] {
  background-color: transparent;
}

.auth-container .input:hover {
  cursor: pointer;
}

.auth-container .input input::placeholder {
  color: white;
}

.auth-container a {
  color: white;
  margin-top: 0.5em;
  text-decoration: none;
  display: inline-block;
  font-size: 0.9em;
}

.auth-container a:hover {
  text-decoration: underline;
}

.log-in-button {
  width: 170px;
  height: 2.5em;
  background-color: orange;
  margin: 0 auto;
  border: medium solid yellow;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5em;
}

.log-in-button p {
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 5px;
  text-shadow: 2px 2px 10px white;
}

.log-in-button:hover {
  cursor: pointer;
  background-color: orangered;
  border-color: orange;
}

.or-text {
  font-size: 1.8em;
  font-weight: 600;
}

.create-account-button {
  width: 260px;
  height: 2em;
  background-color: #29abe2;
  border: medium solid white;
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 0.8em;
  display: flex;
  align-items: center;
}

.create-account-button p {
  font-weight: 600;
  margin-bottom: 2px;
}

.create-account-button svg {
  width: 20px;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.create-account-button svg path {
  color: gold;
}

.create-account-button:last-child {
  background-color: #214798;
}

.create-account-button:last-child svg {
  width: 14px;
  margin-left: 0.7em;
}

.create-account-button:last-child svg path {
  color: white;
}

.create-account-button:hover {
  cursor: pointer;
  box-shadow: 2px 2px 10px #29abe2;
}

.auth-error {
  color: red !important;
  font-size: 0.9em;
}