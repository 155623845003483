.mobile-exit-icon {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 3vh;
  padding-right: 5vw;
  z-index: 9999999;
}

.mobile-exit-icon img {
  height: 4vh;
}

.mobile-exit-icon:hover img {
  height: 4.5vh;
  cursor: pointer;
}